import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import grey from '@serviceslabs/material-ui-pro/colors/grey';

import { Layout, Section } from '../components/common';
import { RichText } from '../components/common/prismic';

import { withPreviewData } from '../util/preview';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
    },
    effectiveDate: {
        color: grey[400],
    },
    content: {
        textAlign: 'left',
    },
    richText: {
        '& p': {
            marginTop: '1em',
            marginBottom: '1em',
        },
        '& h2, & h3, & h4, & h5, & h6': {
            marginTop: '2em',
        },
    },
}));

const ProjectType = ({ data: staticData, location }) => {
    const data = withPreviewData(staticData);
    const {
        metadata_title: metadataTitle,
        metadata_description: metadataDescription,
        json_ld: jsonLD,
        title,
        effective_date: effectiveDate,
        main_content: mainContent,
    } = data.prismicTerms.data;

    const classes = useStyles();
    return (
        <Layout
            location={location}
            navBarProps={{
                scrollThreshold: 0,
            }}
            customMetaData={{
                title: (metadataTitle || {}).text,
                description: (metadataDescription || {}).text,
                jsonLD: (jsonLD || {}).text,
            }}
        >
            <Section>
                <Typography className={classes.title} variant="h1">
                    {title.text}
                </Typography>
                <Typography className={classes.effectiveDate}>
                    {effectiveDate.text}
                </Typography>
                <Typography className={classes.content} component="div">
                    <RichText
                        classes={{ root: classes.richText }}
                        html={mainContent.html}
                    />
                </Typography>
            </Section>
        </Layout>
    );
};

export default ProjectType;

ProjectType.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export const query = graphql`
    query TermsQuery($uid: String!) {
        prismicTerms(uid: { eq: $uid }) {
            data {
                metadata_title {
                    text
                }
                metadata_description {
                    text
                }
                json_ld {
                    text
                }
                title {
                    text
                }
                effective_date {
                    text
                }
                main_content {
                    html
                }
            }
        }
    }
`;
